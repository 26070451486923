import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import ShimmerButton from '../components/magicui/shimmer-button';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import CircularProgress from '@mui/material/CircularProgress';

const stripePromise = loadStripe('pk_live_51Pb5rcHnQLCbZW4Yq03X1C19tZqlBX4uVm0DO5GQ18lpOjYKhWQeo04xLoiwP4Zv91YUJacL9F1LEtelAcdj0MRm008TM7yz7B');
//const stripePromise = loadStripe('pk_test_51M5XfmHtRheYJmNr5Jtn8vwuNv6vqd7C3YpZyw9dOCFF7OOa1bBib3P126COAbYy6LtV9UGjmlmNo8CCTEjFCwuv00cdOSA8lZ');


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#4A90E2', 
    color: theme.palette.common.white,
    padding: '6px', 
    fontSize: '0.75rem',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '0.65rem', 
    padding: '6px', 
    whiteSpace: 'nowrap',
    overflow: 'hidden', 
    textOverflow: 'ellipsis', 
    minWidth: '70px', 
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#ffeb3b',
  },
  '&:nth-last-of-type(-n+2)': {
    backgroundColor: '#FFF9C4',
    color: '#000',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface CompanyType {
  salaryNetA: number;
  salaryNetB: number;
  salaryNetC: number;
  socialCotisationA: number;
  socialCotisationB: number;
  socialCotisationC: number;
  corporationTaxA: number;
  corporationTaxB: number;
  corporationTaxC: number;
  incomeTaxA: number;
  incomeTaxB: number;
  incomeTaxC: number;
  totalSocialSecurityContributionsA: number;
  totalSocialSecurityContributionsB: number;
  totalSocialSecurityContributionsC: number;
  cashBalanceA: number;
  cashBalanceB: number;
  cashBalanceC: number;
  additionalData1?: { C1: string; D1: string; };
  additionalData2?: { H1: string; I1: string; };
  additionalData3?: { B10: string; D10: string; };
  additionalData4?: { H10: string; I10: string; };
}

export interface DataType {
  bestStatus: string;
  ei: CompanyType;
  micro: CompanyType;
  sarl: CompanyType;
  sas: CompanyType;
}

export interface DataTableProps {
  data: DataType;
  userData: any; // Ajoutez cette ligne pour accepter les données utilisateur
}

const DataTable: React.FC<DataTableProps> = ({ data, userData }) => {
  const [, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const imagePaths = [
    "/img/BP pour apercu site maformejuridique.fr (1)-1.png",
    "/img/BP pour apercu site maformejuridique.fr (1)-2.png",
    "/img/BP pour apercu site maformejuridique.fr (1)-3.png",
    "/img/BP pour apercu site maformejuridique.fr (1)-4.png",
  ];

  const handlePayment = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, p0?: string) => {
    event.preventDefault();

    const stripe = await stripePromise;
    if (!stripe) {
      return;
    }

    try {
      setLoading(true);
      //const response = await axios.post('https://fbconseil-c706cd2b1837.herokuapp.com/stripe/create-checkout-session', {
      const response = await axios.post('http://localhost:3000/stripe/create-checkout-session', {

        data: userData,
        metadata: {
          name: userData.name,
          firstName: userData.firstName,
          email: userData.emailAddress,
        },
      });

      if (response.status !== 200) {
        console.error('Error creating checkout session:', response);
        return;
      }

      const session = response.data;
      const { id } = session; // Récupérez l'ID de la session

      const result = await stripe.redirectToCheckout({ sessionId: id });

      if (result.error) {
        console.error('Error redirecting to Stripe:', result.error.message);
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
      setErrorMessage('Une erreur est survenue');
    } finally {
      setLoading(false);
    }
  };

  const handlePayment1 = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    window.open('https://calendly.com/fbsimulateurs/30min', '_blank');
  };

  const handlePayment2 = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    window.open('https://calendly.com/fbsimulateurs/rendez-vous-conseils-budget-previsionnel', '_blank');
  };


  const formatNumber = (num: number | null | undefined) => {
    if (num === null || num === undefined) {
      return '-'; // Ou une autre valeur par défaut, comme une chaîne vide ou 0
    }
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };
  
  
  
  
  return (
    <>
      <span className='text-4xl font-bold text-gray-600'>
      Mon meilleur statut : {data.bestStatus || "Statut non disponible"}
        <br />
        <span className='text-xl text-gray-600 font-semibold'>
          ⚠️Cette simulation ne tient pas compte des particularités de la situation personnelle (ARE, activité simultanée, ...). <br/> Contactez-nous pour une simulation personnalisée de votre projet.
        </span>
        <br />
        <div className='flex flex-col md:flex-row md:justify-between'>
          <div className='flex flex-col md:w-2/3'>
            <span className='text-sm flex items-center justify-between mt-4'>
              <span>🔵 J'obtiens mon Budget Prévisionnel issu de la simulation pour 50€ HT</span>
              <ShimmerButton
                className="bg-blue-500 text-white ml-4 font-bold text-sm py-2 px-3"
                data-amount="5000"
                onClick={handlePayment}
                disabled={loading} // Désactiver le bouton pendant le chargement
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Payer'}
              </ShimmerButton>
            </span>
            <span className='text-sm flex items-center justify-between mt-4'>
              <span>🔵 J'obtiens un Rendez-vous conseil avec un professionnel de la création pour 80€ HT</span>
              <ShimmerButton
                className="bg-blue-500 text-white ml-4 font-bold text-sm py-2 px-3"
                onClick={handlePayment1}
              >
                Payer
              </ShimmerButton>
            </span>
            <span className='text-sm flex items-center justify-between mt-4'>
              <span>🔵 J'obtiens mon Budget Prévisionnel personnalisé ET un Rendez-vous conseil avec <br/>un professionnel en création pour 120€ HT</span>
              <ShimmerButton
                className="bg-blue-500 text-white ml-4 font-bold text-sm py-2 px-3"
                data-amount="10000"
                onClick={handlePayment2}
              >
                Payer
              </ShimmerButton>
            </span>
          </div>
          <div className='flex flex-col md:w-1/3 items-center mt-4 md:mt-0'>
            <p className='text-sm mb-4'>Aperçu de votre budget prévisionnel:</p>
            <div className="overflow-y-auto max-h-60 w-full md:w-80 flex flex-col items-end mt-4 md:mt-0">
              {imagePaths.map((path, index) => (
                <img key={index} src={path} alt={`Page ${index + 1}`} className="w-full h-auto mb-4" />
              ))}
            </div>
          </div>
        </div>
      </span>
      <Grid container spacing={2}>
        {["ei", "sarl", "micro", "sas"].map((key) => {
          const companyData = data[key as keyof DataType];
          const type = key.toUpperCase();
  
          if (typeof companyData === 'string') {
            return null; // Skip if companyData is not of type CompanyType
          }
  
          let salaireMessage = "SALAIRE NET";
          let cotisationsMessage = "";
          let impotsSocieteMessage = "IMPOT SUR LES SOCIETES";
          let impotsRevenuMessage = "IMPOT SUR LE REVENU";
          let totalChargeSocialeMessage = "TOTAL CHARGES SOCIALES ET IMPOT SUR LE REVENU";
          let soldeTresorerie = "SOLDE DE TRESORERIE";
  
          if (type === "EI" || type === "MICRO") {
            cotisationsMessage = "COTISATIONS SOCIALES";
          }
  
          if (type === "SARL") {
            cotisationsMessage = "COTISATIONS SOCIALES TNS SARL";
          }
  
          if (type === "SAS") {
            cotisationsMessage = "COTISATIONS SOCIALES PRESIDENT SAS";
          }
  
          return (
            <Grid item xs={12} sm={6} key={key}>
              <Card>
                <CardContent>
                <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto' }}>


                    <Table aria-label="customized table">
                    <TableHead>
  <TableRow>
    <StyledTableCell align="center" colSpan={type === "MICRO" ? 1 : 1} style={{ fontWeight: 'bold' }}>
      {type}
    </StyledTableCell>
    {type !== "MICRO" && (
      <StyledTableCell align="center" style={{ backgroundColor: '#4A90E2', color: 'white', fontWeight: 'bold', fontSize: '0.775rem', paddingLeft: '30px' }}>
        N
      </StyledTableCell>
    )}
    {type === "MICRO" ? (
      <StyledTableCell align="center" colSpan={3} style={{ backgroundColor: '#4A90E2', color: 'white', fontWeight: 'bold', fontSize: '0.775rem' }}>
        {companyData.additionalData3?.B10 || ''}
      </StyledTableCell>
    ) : (
      <>
        <StyledTableCell align="center" style={{ fontWeight: 'bold', paddingLeft: '30px' }}>
          {companyData.additionalData1 ? companyData.additionalData1.C1 : companyData.additionalData2 ? companyData.additionalData2.H1 : companyData.additionalData4 ? companyData.additionalData4.H10 : 'N+1'}
        </StyledTableCell>
        <StyledTableCell align="center" style={{ fontWeight: 'bold', paddingLeft: '30px' }}>
          {companyData.additionalData1 ? companyData.additionalData1.D1 : companyData.additionalData2 ? companyData.additionalData2.I1 : companyData.additionalData4 ? companyData.additionalData4.I10 : 'N+2'}
        </StyledTableCell>
      </>
    )}
  </TableRow>
</TableHead>


                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell component="th" scope="row">
                            {salaireMessage}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {formatNumber(companyData.salaryNetA)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {formatNumber(companyData.salaryNetB)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {formatNumber(companyData.salaryNetC)}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell component="th" scope="row">
                            {cotisationsMessage}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {formatNumber(companyData.socialCotisationA)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {formatNumber(companyData.socialCotisationB)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {formatNumber(companyData.socialCotisationC)}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell component="th" scope="row">
                            {impotsSocieteMessage}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {formatNumber(companyData.corporationTaxA)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {formatNumber(companyData.corporationTaxB)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {formatNumber(companyData.corporationTaxC)}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell component="th" scope="row">
                            {impotsRevenuMessage}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {formatNumber(companyData.incomeTaxA)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {formatNumber(companyData.incomeTaxB)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {formatNumber(companyData.incomeTaxC)}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell component="th" scope="row">
                            {totalChargeSocialeMessage}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {formatNumber(companyData.totalSocialSecurityContributionsA)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {formatNumber(companyData.totalSocialSecurityContributionsB)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {formatNumber(companyData.totalSocialSecurityContributionsC)}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell component="th" scope="row">
                            {soldeTresorerie}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {formatNumber(companyData.cashBalanceA)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {formatNumber(companyData.cashBalanceB)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {formatNumber(companyData.cashBalanceC)}
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
  
};

export default DataTable;